import Game from "../Components/Game";
import { useState } from "react";

import React from "react";
import { css } from "@emotion/css";
export default function Home() {
  const [isGameStart, setIsGameStart] = useState(false);
  return (
    <>
      <main
        className={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 6rem;
          min-height: 100vh;
          background-color: #fff;
        `}
      >
        {isGameStart ? (
          <Game />
        ) : (
          <div
            className={css`
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-align: center;
            `}
          >
            <div
              className={css`
                width: 20rem;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <img
                className={css`
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                `}
                src={
                  window.location.href.endsWith("/index.html")
                    ? window.location.href.replace("/index.html", "/")
                    : window.location.href + "startScreen.png"
                }
                alt="start screen img"
              />
            </div>
            <div
              className={css`
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 2rem;
                color: #000;
                font-size: 2rem;

                margin-top: 2rem;
                margin-bottom: 7rem;
              `}
            >
              <span
                className={css`
                  :hover {
                    color: #a00;
                    cursor: pointer;
                  }
                `}
                onClick={() => {
                  setIsGameStart(true);
                }}
              >
                Start Game
              </span>
            </div>
            <div>
              Game Made For The{" "}
              <a href="https://itch.io/jam/ludwig-2023">Ludwig Game Jam 2023</a>{" "}
              By <a href="https://daviddodda.com">David Dodda</a> <br />{" "}
              {/* TODO  add github repo link*/}
              <br />{" "}
              <a href="https://github.com/daviddodda1/coots_adventures">
                Project Github
              </a>
            </div>
          </div>
        )}
      </main>
    </>
  );
}
